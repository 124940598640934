<template>
    <div class="overflow-y-auto pb-10 antialiased">
        <div class="max-w-screen-lg flex flex-col gap-10 px-0 md:px-16 py-6">
            <h1 class="text-2xl tracking-tighter font-semibold">Design Systems</h1>
            <p>This is an <strong>internal only</strong> page that showcases components in our design system.</p>
            <div>
                <h3 class="font-semibold text-xl tracking-tight mb-2">Components</h3>
                <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
                    <component
                        :is="ComponentContainer"
                        title="Accordion"
                        description="This is the most bare bones Accordion, but its easy enought to extend into a custom version (ie. our nadia notes accordions!)">
                        <BaseAccordion :accordion-ids="accordionIds">
                            <template v-for="id in accordionIds" :key="id" #[`trigger-${id}`]> Trigger {{ id }} </template>
                            <template v-for="id in accordionIds" :key="id" #[`content-${id}`]> Content {{ id }} </template>
                        </BaseAccordion>
                    </component>
                    <component
                        :is="ComponentContainer"
                        title="Button"
                        description="Button themes are not exactly the best (ie. startChat is a temp name given but this should be given a theme name since its so widely used)">
                        <BaseButton theme="primary">primary</BaseButton>
                        <BaseButton theme="secondary">secondary</BaseButton>
                        <BaseButton theme="startChat">startChat</BaseButton>
                        <BaseButton theme="ghost">ghost</BaseButton>
                    </component>
                    <component :is="ComponentContainer" title="Dialog" description="Dialog has its own mobile style that makes it look like a drawer">
                        <BaseButton theme="primary" @click="isDialogShowing = true">Press me to open dialog</BaseButton>
                        <BaseDialog v-if="isDialogShowing" @cancel="isDialogShowing = false" @close="isDialogShowing = false">
                            Hello I am a very basic dialog with some text content
                        </BaseDialog>
                    </component>
                    <component :is="ComponentContainer" title="Notification">
                        <BaseNotification theme="primary" text="I am a primary notification" />
                        <BaseNotification theme="secondary" text="I am a secondary notification" />
                        <BaseNotification theme="info" text="I am an info notification (but this is named incorrectly since we use this as error?)" />
                    </component>
                    <component :is="ComponentContainer" title="Tabs" description="Tab component with ability to customize tab trigger + content for each slot.">
                        <BaseTabs :tab-ids="tabIds">
                            <template v-for="id in tabIds" :key="id" #[`trigger-${id}`]> Tab {{ id }} </template>
                            <template v-for="id in tabIds" :key="id" #[`content-${id}`]> Content {{ id }} </template>
                        </BaseTabs>
                    </component>
                    <component
                        :is="ComponentContainer"
                        title="Skeleton Loader"
                        description="Skeleton loader component is very bare bones, we can style it to whatever shape we want, right now only supports pulsing animation.">
                        <SkeletonLoader class="rounded-full w-12 h-12 mb-2" />
                        <SkeletonLoader class="rounded-md w-full h-4 mb-2" />
                        <SkeletonLoader class="rounded-md w-full h-4 mb-2" />
                    </component>
                    <component
                        :is="ComponentContainer"
                        title="Collapsible Section"
                        description="This is a wrapper component that can take a custom trigger and display custom content. The expand/collapse is animated and it is fully a11y compliant (screen reader + keyboard).">
                        <BaseCollapsible>
                            <template #trigger> Trigger </template>
                            <template #content> This is just a bunch of random text content I put in to show that it can expand.</template>
                        </BaseCollapsible>
                    </component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LeftSidebarLayout from "~vue/layouts/LeftSidebarLayout.vue";
export default { layout: LeftSidebarLayout };
</script>

<script setup>
import BaseAccordion from "~vue/components/BaseAccordion.vue";
import BaseButton from "~vue/components/BaseButton.vue";
import BaseCollapsible from "~vue/components/BaseCollapsible.vue";
import BaseDialog from "~vue/components/BaseDialog.vue";
import BaseNotification from "~vue/components/BaseNotification.vue";
import BaseTabs from "~vue/components/BaseTabs.vue";
import SkeletonLoader from "~vue/components/SkeletonLoader.vue";
import { h, ref } from "vue";
// This is an internal only page to showcase some of the design system related assets and components we have
const isDialogShowing = ref(false);

const accordionIds = ["1", "2", "3"];

const tabIds = ["1", "2", "3"];

const ComponentContainer = (props, { slots }) => {
    return h("div", [h("p", { class: "font-medium tracking-tight mb-2" }, props.title), h("p", { class: "mb-4" }, props.description), slots.default()]);
};
</script>

<style scoped></style>
